import React from "react";
import { Chart } from "react-google-charts";

function organizationsTypes(props) {
  const options = {
    legend: { position: "none" },
    chartArea: { width: "100%", height: "100%" },
  };

  return (
    <div
      className="col-lg-4 col-sm-4 shadow-lg"
      style={{
        backgroundColor: "#ffffff",
        padding: "30px",
        borderRadius: "20px",
      }}
    >
      <div className="chart-title">Organizations Type</div>
      <div class="horizontal-line"></div>
      <Chart
        chartType="PieChart"
        loader={<div>Loading Chart</div>}
        data={props?.organizationsTypes}
        options={options}
        width={"100%"}
        height={"auto"}
      />
    </div>
  );
}

export default organizationsTypes;

import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

function numberOfOrg(props) {
  return (
    <>
      <DashboardCardStat
        title={props.total_organizations}
        description="Number of organizations"
        icon={
          <div className="circle-container">
            {" "}
            <div className="centered-icon">
              <FontAwesomeIcon icon={faBuilding} size="xs" />
            </div>
          </div>
        }
      />
    </>
  );
}

export default numberOfOrg;

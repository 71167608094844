import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

function volunteersPoint(props) {
  return (
    <>
      <DashboardCardStat
        title={props.total_volunteers_point}
        description="Volunteers Point"
        icon={
          <div className="circle-container">
            <FontAwesomeIcon icon={faGlobe} />
          </div>
        }
      />
    </>
  );
}

export default volunteersPoint;

import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

//redux
import authReducer from "./auth/authReducer";
import nationalityReducer from "./nationality/nationalityReducer";
import reasonsReducer from "./Reasons/ReasonsReducer";
import contactsReducer from "./Contacts/ContactsReducer";
import countryReducer from "./country/countryReducer";
import governateReducer from "./governate/governateReducer";
import districtReducer from "./district/districtReducer";
import carouselReducer from "./carousel/carouselReducer";
import faqReducer from "./faq/faqReducer";

import volunteerReducer from "./volunteer/volunteerReducer";
import organizationReducer from "./organization/organizationReducer";
import institutionsReducer from "./Institutions/InstitutionsReducer";
import skillsReducer from "./Skills/SkillsReducer";
import emailReducer from "./email_subscription/emailReducer";
import volunteerSpotlightsReducer from "./volunteerSpotlights/volunteerSpotlightsReducer";
import blogReducer from "./blog/blogReducer";
import volunteerSpotlightsQAReducer from "./volunteerSpotlightsQA/volunteerSpotlightsQAReducer";
import videosReducer from "./videos/videosReducer";
import impactReducer from "./impact/impactReducer";
import episodeReducer from "./episode/episodeReducer";
import TestimonialReducer from "./testimonial/testimonialReducer";
import quizReducer from "./quiz/quizReducer";
import quizAnswerReducer from "./quizAnswer/quizAnswerReducer";
import disabilityReducer from "./disability/disabilityReducer";
import sectorReducer from "./sector/sectorReducer";
import adminReducer from "./admin/adminReducer";
import opportunityReducer from "./opportunity/opportunityReducer";
import communityActionReducer from "./communityAction/communityActionReducer";
import episodeQuizReducer from "./episodeQuiz/episodeQuizReducer";
import episodeVideoReducer from "./episodeVideo/episodeVideoReducer";
import masterclassReducer from "./Masterclass/MasterclassReducer";
import episodeQuizanswerReducer from "./episodeQuizAnswer/episodeQuizAnswerReducer";
import teamReducer from "./Team/teamReducer";
import constantReducer from "./constant/constantReducer";
import DonateReducer from "./Donation/DonateReducer";
import TrustedPartReducer from "./TrustedPart/TrustedPartReducer";
import MasterClassTestimonialReducer from "./MasterClassTestimonial/MasterClassTestimonialReducer";
import StatReducer from "./Stat/StatReducer";
import episodeQuizUserReducer from "./episodeQuizUser/episodeQuizUserReducer";
import organizationFeedbackReducer from "./organizationFeedback/organizationFeedbackReducer";
import opportunityFeedbacksReducer from "./opportunityFeedbacks/opportunityFeedbacksReducer";
import organizationEvalReducer from "./organizationEval/organizationEvalReducer";
import textReducer from "./text/textReducer";

const middleware = [thunk];

const rootReducer = combineReducers({
  authReducer: authReducer,
  nationalityReducer: nationalityReducer,
  countryReducer: countryReducer,
  governateReducer: governateReducer,
  districtReducer: districtReducer,
  carouselReducer: carouselReducer,
  faqReducer: faqReducer,

  volunteerReducer: volunteerReducer,
  organizationReducer: organizationReducer,
  emailReducer: emailReducer,
  reasonsReducer: reasonsReducer,
  videosReducer: videosReducer,
  contactsReducer: contactsReducer,
  institutionsReducer: institutionsReducer,
  skillsReducer: skillsReducer,
  blogReducer: blogReducer,
  volunteerSpotlightsReducer: volunteerSpotlightsReducer,
  volunteerSpotlightsQAReducer: volunteerSpotlightsQAReducer,
  impactReducer: impactReducer,
  episodeReducer: episodeReducer,
  TestimonialReducer: TestimonialReducer,
  quizReducer: quizReducer,
  quizAnswerReducer: quizAnswerReducer,
  disabilityReducer: disabilityReducer,
  sectorReducer: sectorReducer,
  adminReducer: adminReducer,
  episodeQuizReducer: episodeQuizReducer,
  episodeVideoReducer: episodeVideoReducer,
  opportunityReducer: opportunityReducer,
  communityActionReducer: communityActionReducer,
  masterclassReducer: masterclassReducer,
  episodeQuizanswerReducer: episodeQuizanswerReducer,
  episodeQuizUserReducer: episodeQuizUserReducer,

  teamReducer: teamReducer,
  constantReducer: constantReducer,
  DonateReducer: DonateReducer,
  MasterClassTestimonialReducer: MasterClassTestimonialReducer,
  TrustedPartReducer: TrustedPartReducer,
  StatReducer: StatReducer,
  organizationFeedbackReducer: organizationFeedbackReducer,
  opportunityFeedbacksReducer: opportunityFeedbacksReducer,
  organizationEvalReducer: organizationEvalReducer,
  textReducer: textReducer,
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;

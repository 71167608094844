import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material";
import { tokens } from "../Theme/Theme.js";

//components
import Sidebar from "../layout/Sidebar.js";
import Loading from "../layout/Loading.js";
import Header from "../Header/Header.js";
import TextEditModal from "./TextEditModal.js";

//redux
import { getTexts } from "../../redux/text/textActions.js";

//utils
import isAuth from "../../utils/isAuth.js";

function Text() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, texts, error } = useSelector((state) => state.textReducer);
  const [records, setRecords] = useState([]);
  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [textEn, setTextEn] = useState("");
  const [textAr, setTextAr] = useState("");

  useEffect(() => {
    if (!isAuth(dispatch)) navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(getTexts());
  }, [dispatch]);

  useEffect(() => {
    if (texts && texts.data) {
      const mappedRecords = texts.data.map((text) => {
        return {
          key: text.id,
          id: text.id,
          name: text.name,
          textEn: text.textEn,
          textAr: text.textAr,
          createdAt: text.createdAt,
        };
      });
      setRecords(mappedRecords);
    }
  }, [texts]);

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "textEn", headerName: "Text En", flex: 1 },
    { field: "textAr", headerName: "Text Ar", flex: 1 },
    { field: "createdAt", headerName: "Created At", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      width: 100,
      renderCell: (record) => {
        return (
          <Fragment>
            <button
              data-toggle="modal"
              data-target="#update-volunteer-modal"
              className="actions"
              style={{ marginRight: "5px" }}
              onClick={() => {
                setId(record.row.id);
                setName(record.row.name);
                setTextEn(record.row.textEn);
                setTextAr(record.row.textAr);
              }}
            >
              <i className="fa fa-edit"></i>
            </button>
          </Fragment>
        );
      },
    },
  ];

  return (
    <div>
      <div className="d-flex" id="wrapper">
        <Sidebar />
        <TextEditModal
          id={id}
          name={name}
          textEn={textEn}
          textAr={textAr}
          setName={setName}
          setTextEn={setTextEn}
          setTextAr={setTextAr}
        />
        <div id="page-content-wrapper">
          <div className="container-fluid">
            <div style={{ textAlign: "center" }}>
              {error && <div style={{ color: "red" }}> {error.message}</div>}
            </div>

            {loading ? (
              <Loading />
            ) : (
              <div>
                <Box m="20px">
                  <Header title="Text to edit" subtitle="List of texts" />
                  <Box
                    m="40px 0 0 0"
                    height="75vh"
                    sx={{
                      "& .MuiDataGrid-root": {
                        border: "none",
                      },
                      "& .MuiDataGrid-cell": {
                        borderBottom: "none",
                      },
                      "& .name-column--cell": {
                        color: colors.greenAccent[300],
                      },
                      "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: colors.greenAccent[700],
                        borderBottom: "none",
                      },
                      "& .MuiDataGrid-virtualScroller": {
                        backgroundColor: colors.primary[400],
                      },
                      "& .MuiDataGrid-footerContainer": {
                        borderTop: "none",
                        backgroundColor: colors.greenAccent[700],
                      },
                      "& .MuiCheckbox-root": {
                        color: `${colors.greenAccent[200]} !important`,
                      },
                      "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                        color: `${colors.grey[100]} !important`,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      },
                    }}
                  >
                    <DataGrid
                      className="table"
                      rows={records}
                      columns={columns}
                      components={{ Toolbar: GridToolbar }}
                      // onColumnVisibilityChange={handleColumnVisibilityChange}
                      // checkboxSelection={true}
                    />
                  </Box>
                </Box>
              </div>
            )}

            <br />
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Text;

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

//components
import App from "./App";

//redux
import store from "./redux/store";

//css
import "./index.css";

//jquery
import $ from "jquery";
window.jQuery = $;
window.$ = $;
global.jQuery = $;

ReactDOM.render(
  <Provider store={store}>
    {" "}
    <App />{" "}
  </Provider>,
  document.getElementById("root")
);

$('.modal[data-reset="true"]').on("shown.bs.modal", () =>
  $("input[name != 'timestamp']").val("")
);

$(".modal").on("shown.bs.modal", () =>
  $('input[data-reset-input="true"]').val("")
);

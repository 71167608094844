import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faTable } from "@fortawesome/free-solid-svg-icons/faTable";
import { faBlog } from "@fortawesome/free-solid-svg-icons/faBlog";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faAddressBook } from "@fortawesome/free-solid-svg-icons";
import { faMapMarked } from "@fortawesome/free-solid-svg-icons";
import { faWheelchair } from "@fortawesome/free-solid-svg-icons";
import { faHandHoldingHeart } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faClipboardCheck } from "@fortawesome/free-solid-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
import { faUniversity } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import {
  faChalkboardTeacher,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { faFlag } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faDice } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faIndustry } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faHandsHelping } from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { faHandshake } from "@fortawesome/free-solid-svg-icons";
import { faHandSparkles } from "@fortawesome/free-solid-svg-icons";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { faUserFriends } from "@fortawesome/free-solid-svg-icons";

import img2 from "./logo-black.svg";
import { logoutAdmin } from "../../redux/auth/authActions";
import "./sidebar.css";
import { useLocation } from "react-router-dom";

import { faList } from "@fortawesome/free-solid-svg-icons/faList";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [expanded, setExpanded] = useState(
    localStorage.getItem("sidebarExpanded") === "true"
  );
  const [activeLink, setActiveLink] = useState("");

  useEffect(() => {
    // Set the last visited page when the location changes
    setActiveLink(location.pathname);
  }, [location]);

  useEffect(() => {
    localStorage.setItem("sidebarExpanded", expanded);
  }, [expanded]);

  let sidebarObj;
  const [isShifted, setIsShifted] = useState(false);

  function onCreate() {
    sidebarObj.style.visibility = "visible";
  }

  // Toggle (Open/Close) the Sidebar
  function toggleClick() {
    const container = document.querySelector(".container-fluid");
    const toggle = document.querySelector(".toggle");

    if (sidebarObj.style.visibility === "visible") {
      sidebarObj.style.visibility = "hidden";
      setIsShifted(!isShifted);

      // Adjust the container's width when the sidebar is hidden
      container.style.width = "100%";
      toggle.style.transition = "margin-left 0.3s ease-in-out";
    } else {
      sidebarObj.style.visibility = "visible";
      setIsShifted(!isShifted);

      // Adjust the container's width when the sidebar is visible
      container.style.width = "100%"; // Set it to 100% when the sidebar is open
      container.style.marginLeft = " 0rem";
    }
  }

  return (
    <div>
      <button
        className={`toggle ${isShifted ? "shift" : "notToggled"}`}
        id="menu-toggle"
        onClick={toggleClick}
      >
        <FontAwesomeIcon icon={faList} />
      </button>

      <div
        className="sidebar"
        id="sidebar-wrapper"
        ref={(sidebar) => (sidebarObj = sidebar)}
        style={{ visibility: "hidden" }}
        onLoad={onCreate}
      >
        <div className="list-group">
          <Link
            to="/dashboard"
            className="list-group-item list-group-item-action sideb"
          >
            <img src={img2} className="image" alt="My Image" />
          </Link>
          <Link
            to="/dashboard"
            className={`list-group-item list-group-item-action sideb ${
              activeLink === "/dashboard" ? "active" : ""
            }`}
          >
            <div className="sideBar-icon">
              <FontAwesomeIcon icon={faHome} size="xs" />
            </div>
            <div className="sideBar-label">Dashboard</div>
          </Link>

          {/* Admin */}
          <div>
            <h5 style={{ marginLeft: "20px" }}>Admin</h5>
            <Link
              to="/admins"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/admins" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faTable} size="xs" />
              </div>
              <div className="sideBar-label">Admins</div>
            </Link>
          </div>

          {/* Organizations */}
          <div className="sidebar-section">
            <h5 style={{ marginLeft: "20px" }}>Organizations</h5>
            <Link
              to="/organizations"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/organizations" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faBuilding} size="xs" />
              </div>
              <div className="sideBar-label">Organizations</div>
            </Link>
            <Link
              to="/organizationFeedback"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/organizationFeedback" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faBuilding} size="xs" />
              </div>
              <div className="sideBar-label">Organization Feedback</div>
            </Link>
            <Link
              to="/organizationEval"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/organizationEval" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faBuilding} size="xs" />
              </div>
              <div className="sideBar-label">Organization Evaluations</div>
            </Link>
            <Link
              to="/opportunities"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/opportunities" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faDice} size="xs" />
              </div>
              <div className="sideBar-label">Opportunities</div>
            </Link>
            <Link
              to="/opportunityFeedback"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/opportunityFeedback" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faDice} size="xs" />
              </div>
              <div className="sideBar-label">Opportunity Feedback</div>
            </Link>
          </div>

          {/* Volunteer */}
          <div className="sidebar-section">
            <h5 style={{ marginLeft: "20px" }}>Volunteer</h5>
            <Link
              to="/volunteers"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/volunteers" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faHandSparkles} size="xs" />
              </div>
              <div className="sideBar-label"> Volunteers</div>
            </Link>
            <Link
              to="/volunteer_spotlights"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/volunteer_spotlights" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faLightbulb} size="xs" />
              </div>
              <div className="sideBar-label"> Volunteer Spotlights</div>
            </Link>
            <Link
              to="/volunteer_spotlights_QA"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/volunteer_spotlights_QA" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faUserFriends} size="xs" />
              </div>
              <div className="sideBar-label"> Volunteer Spotlights QA</div>
            </Link>
            <Link
              to="/opentowork"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/opentowork" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faBriefcase} size="xs" />
              </div>
              <div className="sideBar-label">Open To Work</div>
            </Link>
            <Link
              to="/community_actions"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/community_actions" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faUsers} size="xs" />
              </div>
              <div className="sideBar-label">Community</div>
            </Link>
          </div>

          {/* MasterClass */}
          <div className="sidebar-section">
            <h5 style={{ marginLeft: "20px" }}>MasterClass</h5>
            <Link
              to="/episode"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/episode" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faPlayCircle} size="xs" />
              </div>
              <div className="sideBar-label"> Episodes</div>
            </Link>
            <Link
              to="/episodeVideo"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/episodeVideo" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faVideo} size="xs" />
              </div>
              <div className="sideBar-label"> Episode Videos</div>
            </Link>
            <Link
              to="/episodeQuiz"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/episodeQuiz" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faClipboardCheck} size="xs" />
              </div>
              <div className="sideBar-label"> Episode Quizzes</div>
            </Link>
            <Link
              to="/episodeQuizUser"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/episodeQuizUser" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faClipboardCheck} size="xs" />
              </div>
              <div className="sideBar-label"> Episode Quizzes Users</div>
            </Link>
            <Link
              to="/masterclass"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/masterclass" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faChalkboardTeacher} size="xs" />
              </div>
              <div className="sideBar-label"> Master Classes</div>
            </Link>
            <Link
              to="/masterclasstestimonial"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/masterclasstestimonial" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faComment} size="xs" />
              </div>
              <div className="sideBar-label"> Master Classes Testimonials</div>
            </Link>
          </div>

          {/* Platform */}
          <div className="sidebar-section">
            <h5 style={{ marginLeft: "20px" }}>Platform</h5>
            <Link
              to="/carousels"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/carousels" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faChevronRight} size="xs" />
              </div>
              <div className="sideBar-label">Carousels</div>
            </Link>
            <Link
              to="/blogs"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/blogs" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faBlog} size="xs" />
              </div>
              <div className="sideBar-label">Blogs</div>
            </Link>
            <Link
              to="/videos"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/videos" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faVideo} size="xs" />
              </div>
              <div className="sideBar-label"> Videos</div>
            </Link>
            <Link
              to="/testimonial"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/testimonial" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faCommentAlt} size="xs" />
              </div>
              <div className="sideBar-label"> Testimonials</div>
            </Link>
            <Link
              to="/quizzes"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/quizzes" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faClipboardCheck} size="xs" />
              </div>
              <div className="sideBar-label"> Quizzes</div>
            </Link>
            <Link
              to="/faqs"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/faqs" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faQuestionCircle} size="xs" />
              </div>
              <div className="sideBar-label"> Faqs</div>
            </Link>
            <Link
              to="/trustedpart"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/trustedpart" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faHandshake} size="xs" />
              </div>
              <div className="sideBar-label"> Trusted Partners</div>
            </Link>
            <Link
              to="/text"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/masterclass" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faChalkboardTeacher} size="xs" />
              </div>
              <div className="sideBar-label"> Text </div>
            </Link>
          </div>

          {/* Adresses */}
          <div className="sidebar-section">
            <h5 style={{ marginLeft: "20px" }}>Address</h5>
            <Link
              to="/nationalities"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/nationalities" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faFlag} size="xs" />
              </div>
              <div className="sideBar-label">Nationalities</div>
            </Link>
            <Link
              to="/governates"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/governates" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faMapMarkerAlt} size="xs" />
              </div>
              <div className="sideBar-label"> Governates</div>
            </Link>
            <Link
              to="/districts"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/districts" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faMapMarked} size="xs" />
              </div>
              <div className="sideBar-label">Districts</div>
            </Link>
          </div>

          {/* Other */}
          <div className="sidebar-section">
            <h5 style={{ marginLeft: "20px" }}>Other</h5>
            <Link
              to="/reasons"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/reasons" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faHeart} size="xs" />
              </div>
              <div className="sideBar-label"> Reasons</div>
            </Link>
            <Link
              to="/sector"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/sector" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faIndustry} size="xs" />
              </div>
              <div className="sideBar-label"> Sectors</div>
            </Link>
            <Link
              to="/skills"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/skills" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faStar} size="xs" />
              </div>
              <div className="sideBar-label"> Skills</div>
            </Link>
            <Link
              to="/disability"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/disability" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faWheelchair} size="xs" />
              </div>
              <div className="sideBar-label">Disabilities</div>
            </Link>
            <Link
              to="/impact"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/impact" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faBullseye} size="xs" />
              </div>
              <div className="sideBar-label"> Impacts</div>
            </Link>
            <Link
              to="/institutions"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/institutions" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faUniversity} size="xs" />
              </div>
              <div className="sideBar-label"> Institutions</div>
            </Link>
            <Link
              to="/team"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/team" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faHandsHelping} size="xs" />
              </div>
              <div className="sideBar-label"> Teams</div>
            </Link>

            <Link
              to="/email_subscriptions"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/email_subscriptions" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faEnvelope} size="xs" />
              </div>
              <div className="sideBar-label"> Email Subscriptions</div>
            </Link>
            <Link
              to="/contacts"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/contacts" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faAddressBook} size="xs" />
              </div>
              <div className="sideBar-label">Contacts</div>
            </Link>
            <Link
              to="/donate"
              className={`list-group-item list-group-item-action sideb ${
                activeLink === "/donate" ? "active" : ""
              }`}
            >
              <div className="sideBar-icon">
                <FontAwesomeIcon icon={faHandHoldingHeart} size="xs" />
              </div>
              <div className="sideBar-label">Donations</div>
            </Link>
          </div>

          <button
            className="list-group-item list-group-item-action"
            onClick={() => dispatch(logoutAdmin())}
          >
            Logout <FontAwesomeIcon icon={faSignOutAlt} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

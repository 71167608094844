import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

//redux
import { editText } from "../../redux/text/textActions";

function TextEditModal({
  id,
  name,
  textEn,
  textAr,
  setName,
  setTextEn,
  setTextAr,
}) {
  const dispatch = useDispatch();

  const resetForm = () => {
    setName("");
    setTextEn("");
    setTextAr("");
  };

  const editTextHandle = (e) => {
    e.preventDefault();
    dispatch(editText(id, name, textEn, textAr));
    resetForm();
  };

  return (
    <div className="modal fade" id="update-volunteer-modal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-headers">
            <h4 className="modal-title">Edit Text</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              onClick={resetForm}
            >
              &times;
            </button>
          </div>

          <div className="modal-body">
            <form onSubmit={editTextHandle}>
              <div className="row formRow">
                <span className="col-md-3 textForm">Name:</span>
                <input
                  value={name}
                  type="text"
                  placeholder="Enter name"
                  onChange={(e) => setName(e.target.value)}
                  className="  inputt col-md-8 form-controls"
                />
              </div>
              <br />

              <div className="row formRow">
                <span className="col-md-3 textForm">Text English:</span>
                <textarea
                  value={textEn}
                  placeholder="Enter Text English"
                  onChange={(e) => setTextEn(e.target.value)}
                  className="inputt col-md-8 form-controls"
                />
              </div>
              <br />

              <div className="row formRow">
                <span className="col-md-3 textForm">Text Arabic:</span>
                <textarea
                  value={textAr}
                  placeholder="Enter Text Arabic"
                  onChange={(e) => setTextAr(e.target.value)}
                  className="inputt col-md-8 form-controls"
                />
              </div>
              <br />

              <div className="modal-footer">
                <button
                  type="button"
                  className="close-btn"
                  data-dismiss="modal"
                  onClick={resetForm}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="add-btn"
                  data-dismiss="modal"
                  onClick={editTextHandle}
                >
                  Edit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TextEditModal;

import React from "react";
import DashboardCardStat from "../DashboardCardStat";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarOfLife } from "@fortawesome/free-solid-svg-icons";

function firstTimeVolunteers(props) {
  return (
    <>
      <DashboardCardStat
        title={props.firstTimeVolunteers}
        description="First Time Volunteers"
        icon={
          <div className="circle-container">
            <FontAwesomeIcon icon={faStarOfLife} />
          </div>
        }
      />
    </>
  );
}

export default firstTimeVolunteers;

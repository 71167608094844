import {
  FETCH_TEXT_REQUEST,
  FETCH_TEXT_SUCCESS,
  FETCH_TEXT_FAIL,
} from "./textTypes";

export const initialState = {
  loading: false,
  texts: [],
  error: "",
};

const textReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEXT_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_TEXT_SUCCESS:
      return {
        loading: false,
        texts: action.payload,
        error: "",
      };
    case FETCH_TEXT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };
    default:
      return state;
  }
};

export default textReducer;

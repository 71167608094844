import axios from "axios";

//redux
import {
  FETCH_TEXT_REQUEST,
  FETCH_TEXT_SUCCESS,
  FETCH_TEXT_FAIL,
  FETCH_ONE_TEXT_REQUEST,
  FETCH_ONE_TEXT_SUCCESS,
  FETCH_ONE_TEXT_FAIL,
  EDIT_TEXT_REQUEST,
  EDIT_TEXT_SUCCESS,
  EDIT_TEXT_FAIL,
} from "./textTypes";

export const fetchRequest = () => {
  return {
    type: FETCH_TEXT_REQUEST,
  };
};

export const fetchSuccess = (texts) => {
  return {
    type: FETCH_TEXT_SUCCESS,
    payload: texts,
  };
};

export const fetchFail = (error) => {
  return {
    type: FETCH_TEXT_FAIL,
    payload: error,
  };
};

export const getTexts = () => async (dispatch) => {
  dispatch(fetchRequest());
  try {
    let url = process.env.REACT_APP_API + "/text/getTexts";
    const response = await axios.get(url);
    dispatch(fetchSuccess(response.data));
  } catch (e) {
    dispatch(fetchFail(e.response.data));
  }
};

export const editText = (id, name, textEn, textAr) => async (dispatch) => {
  try {
    console.log(id, name, textEn, textAr);

    await axios.put(process.env.REACT_APP_API + "/text/editText/" + id, {
      name,
      textEn,
      textAr,
    });
    dispatch(getTexts());
  } catch (e) {
    console.log(e);
  }
};

import React from "react";
import { useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "../node_modules/jquery/dist/jquery.min";
import "../node_modules/popper.js/dist/popper";

//components
import Login from "./components/auth/Login";
import Dashboard from "./components/dashboard/Dashboard";
import NotFound from "./components/layout/NotFound";
import Nationality from "./components/nationality/Nationality";
import Country from "./components/country/Country";
import Governate from "./components/governate/Governate";
import District from "./components/district/District";
import Carousel from "./components/carousel/Carousel";
import Faq from "./components/faq/Faq";
import Volunteer from "./components/volunteer/Volunteer";
import Organization from "./components/organization/Organization";
import Contact from "./components/Contact/Contact";
import Institution from "./components/Institution/Institution";
import Skill from "./components/Skill/Skill";
import VolunteerSpotlights from "./components/VolunteerSpotlight/VolunteerSpotlights";
import EmailSubscription from "./components/email_susbcription/EmailSubscription";
import Blog from "./components/blog/Blog";
import VolunteerSpotlightsQA from "./components/VolunteerSpotlightsQA/VolunteerSpotlightsQA";
import Videos from "./components/Videos/Videos";
import Impact from "./components/Impact/Impact";
import Episode from "./components/episode/Episode";
import Testimonial from "./components/testimonial/Testimonial";
import Quiz from "./components/quiz/Quiz";
import QuizEditDelete from "./components/quiz/QuizEditDelete";
import Disability from "./components/Disability/Disability";
import Sector from "./components/Sector/Sector";
import EpisodeQuizAnswer from "./components/EpisodeQuiz/EpisodeQuizAnswer";
import EpisodeVideo from "./components/episodeVideo/EpisodeVideo";
import Admin from "./components/admin/Admin";
import Opportunity from "./components/opportunity/Opportunity";
import CommunityAction from "./components/communityAction/CommunityAction";
import Masterclass from "./components/Masterclass/Masterclass";
import EpisodeQuiz from "./components/EpisodeQuiz/EpisodeQuiz";
import Team from "./components/team/team";
import Donate from "./components/Donate/Donate";
import TrustedPart from "./components/TrusetedPart/TrustedPart";
import OpenToWork from "./components/OpenToWork/OpenToWork";
import MasterClassTestimonial from "./components/MasterClassTestimonial/MasterClassTestimonial";
import Reason from "./components/Reason/Reason";
import EpisodeQuizUser from "./components/EpisodeQuizUser/EpisodeQuizUser";
import OrganizationFeedback from "./components/OrganizationFeedback/OrganizationFeedback";
import OpportunityFeedback from "./components/opportunityFeedback/OpportunityFeedback";
import Text from "./components/text/Text";
import OrganizationEval from "./components/OrganizationEval/OrganizationEval";

//utils
import isAuth from "./utils/isAuth";

//css
import "./App.css";
import "./DataTable.css";

function App() {
  const dispatch = useDispatch();

  isAuth(dispatch);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/nationalities" element={<Nationality />} />
          <Route exact path="/countries" element={<Country />} />
          <Route exact path="/governates" element={<Governate />} />
          <Route exact path="/districts" element={<District />} />
          <Route exact path="/carousels" element={<Carousel />} />
          <Route exact path="/faqs" element={<Faq />} />

          <Route exact path="/volunteers" element={<Volunteer />} />
          <Route exact path="/organizations" element={<Organization />} />
          <Route exact path="/reasons" element={<Reason />} />
          <Route exact path="/contacts" element={<Contact />} />
          <Route exact path="/institutions" element={<Institution />} />
          <Route exact path="/impact" element={<Impact />} />
          <Route exact path="/episodeQuiz" element={<EpisodeQuizAnswer />} />
          <Route exact path="/episodeQuizzes" element={<EpisodeQuiz />} />
          <Route exact path="/episodeQuizUser" element={<EpisodeQuizUser />} />
          <Route
            exact
            path="/organizationFeedback"
            element={<OrganizationFeedback />}
          />
          <Route
            exact
            path="/organizationEval"
            element={<OrganizationEval />}
          />
          <Route exact path="/skills" element={<Skill />} />
          <Route exact path="/episodeVideo" element={<EpisodeVideo />} />
          <Route exact path="/masterclass" element={<Masterclass />} />
          <Route exact path="/opportunities" element={<Opportunity />} />
          <Route
            exact
            path="/opportunityFeedback"
            element={<OpportunityFeedback />}
          />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/trustedpart" element={<TrustedPart />} />

          <Route
            exact
            path="/volunteer_spotlights"
            element={<VolunteerSpotlights />}
          />
          <Route
            exact
            path="/volunteer_spotlights_QA"
            element={<VolunteerSpotlightsQA />}
          />
          <Route exact path="/videos" element={<Videos />} />
          <Route exact path="/episode" element={<Episode />} />
          <Route exact path="/testimonial" element={<Testimonial />} />
          <Route exact path="/disability" element={<Disability />} />
          <Route exact path="/sector" element={<Sector />} />
          <Route
            exact
            path="/email_subscriptions"
            element={<EmailSubscription />}
          />
          <Route exact path="/blogs" element={<Blog />} />
          <Route
            exact
            path="/community_actions"
            element={<CommunityAction />}
          />
          <Route exact path="/donate" element={<Donate />} />
          <Route exact path="/quizzes" element={<Quiz />} />
          <Route exact path="/quizzesedit" element={<QuizEditDelete />} />
          <Route exact path="/admins" element={<Admin />} />
          <Route exact path="/opportunities" element={<Opportunity />} />
          <Route exact path="/opentowork" element={<OpenToWork />} />
          <Route
            exact
            path="/masterclasstestimonial"
            element={<MasterClassTestimonial />}
          />
          <Route exact path="/text" element={<Text />} />

          <Route exact path="*" element={<NotFound />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
